import { useState, useEffect } from 'react';

interface DelayConfig {
  enterDelay: number;
  exitDelay: number;
}

function useDelayedRender(isOpen: boolean, { enterDelay, exitDelay }: DelayConfig) {
  const [isMounted, setMounted] = useState(false);
  const [isRendered, setRendered] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const enterTimer = setTimeout(() => setRendered(true), enterDelay);
      setMounted(true);
      return () => clearTimeout(enterTimer);
    } else {
      const exitTimer = setTimeout(() => setMounted(false), exitDelay);
      setRendered(false);
      return () => clearTimeout(exitTimer);
    }
  }, [isOpen, enterDelay, exitDelay]);

  return { mounted: isMounted, rendered: isRendered };
}

export default useDelayedRender;
