import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
const ThemeToggler = dynamic(() => import('components/ThemeToggler'), {
  ssr: false,
});

import Footer2 from 'components/Footer2';
import MobileMenu from 'components/MobileMenu';
import { navigation_items } from 'lib/constants';
import NavItem from './NavItem';
import useTranslation from 'hooks/useTranslation';
import { Spotlight } from 'components/Spotlight';

export default function Container(props) {
  const { t } = useTranslation();
  const [mounted, setMounted] = useState(false);

  // After mounting, we have access to the theme
  useEffect(() => setMounted(true), []);

  const { children, ...customMeta } = props;
  const router = useRouter();
  const meta = {
    title: 'Ayoub Ouaantar • Freelance Designer & Developer',
    description: `Front-End developer in Munich, Germany with a focus on UI/UX. I have been working as a senior front-end developer for over 5 years in international companies such as a travel tech company and a Transportation and logistics.`,
    image: 'https://ouaantar.com/static/favicons/favicon_512x512.png',
    type: 'website',
    ...customMeta,
  };

  return (
    <div className="bg-gray-50 dark:bg-gray-900 flex flex-col min-h-[100vh]">
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta name="description" content={meta.description} />
        <meta property="og:url" content={`https://ayoup.me${router.asPath}`} />
        <link rel="canonical" href={`https://ayoup.me${router.asPath}`} />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="Ayoub Ouaantar" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@AyoupOuaantar" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
        {meta.date && (
          <meta property="article:published_time" content={meta.date} />
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Person',
              email: 'mailto:ayoub@ouaantar.com',
              name: 'Ayoub Ouaantar',
              url: 'https://www.ouaantar.com/',
              image:
                'https://pbs.twimg.com/profile_images/1015251199615029249/s_izsjYC_400x400.jpg',
              jobTitle: 'Front end Developer',
            }),
          }}
        />
      </Head>
      <div className="flex flex-col justify-center">
      <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="white"
      />
        <nav className="flex items-center justify-between w-full relative px-[clamp(2.5em,8vw,8.2em)] border-gray-200 dark:border-gray-700 mx-auto pt-8 pb-8 sm:pb-16  text-gray-900 bg-gray-50  dark:bg-gray-900 bg-opacity-60 dark:text-gray-100">
          <div className="ml-[-0.60rem]">
            <MobileMenu />
            {navigation_items.map((item) => (
              <NavItem
                key={item.text}
                href={item.href}
                text={t(`${item.text}`)}
              />
            ))}
          </div>
          <ThemeToggler />
        </nav>
      </div>
      <main
        id="skip"
        className="flex flex-col justify-center px-8 bg-gray-50 dark:bg-gray-900"
      >
        {children}
      </main>
      <Footer2 />
    </div>
  );
}
