// hooks/useTranslation.jsx

import { useRouter } from 'next/router';
import en from '../public/static/locales/en';
import de from '../public/static/locales/de';

const TRANSLATIONS = { en, de };

export default function useTranslation() {
  const router = useRouter();
  const { locale, asPath } = router;

  const setLocale = (locale) => router.push(asPath, asPath, { locale });

  const t = (keyString, value = '') =>
    TRANSLATIONS[locale][keyString].replace(/{value}/g, value);

  return { t, locale, setLocale };
}
