import React, { useEffect, useState } from 'react';
import useTranslation from 'hooks/useTranslation';
import NowPlaying from './NowPlaying';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';

const Footer = () => {
  const { t, locale, setLocale } = useTranslation();
  const [localTime, setLocalTime] = useState("Loading...");

  useEffect(() => {
    const timerId = setInterval(() => {
      setLocalTime(
        new Intl.DateTimeFormat([], {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
          timeZoneName: "short",
        }).format(new Date())
      );
    }, 0);

    return () => clearInterval(timerId);
  }, []);

  const footerElements = [
    {
      text: 'Version',
      value: t('FOOTER_EDITION', `${new Date().getFullYear()}`),
    },
    {
      text: t('FOOTER_LOCAL_TIME'),
      value: localTime,
    },
  ];

  const languages = [
    { text: t('Local_EN'), locale: 'en' },
    { text: t('Local_DE'), locale: 'de' },
  ];

  return (
    <footer className="flex flex-col mt-auto justify-center items-start px-[clamp(2.5em,8vw,8.2em)] mx-auto w-full mb-8">
      <NowPlaying />
      <div className="flex flex-wrap gap-x-12 gap-y-4">
        {footerElements.map((item, index) => (
          <div key={index} className="space-y-4">
            <h5 className="text-[10px] uppercase tracking-wider opacity-50">
              {item.text}
            </h5>
            <p className="uppercase">{item.value}</p>
          </div>
        ))}
        <div className="space-y-2 btn-dropdown">
          <h5 className="text-[10px] uppercase tracking-wider opacity-50">
            {t('FOOTER_LANGUAGE')}
          </h5>
          <Select onValueChange={setLocale} defaultValue={locale}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Theme" />
            </SelectTrigger>
            <SelectContent>
              {languages.map((item, index) => (
                <SelectItem key={index} value={item.locale}>
                  {item.text}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
