export const navigation_items = [
  { href: '/', text: 'NAV_HOME' },
  { href: '/about', text: 'NAV_ABOUT' },
];

export const social_links = [
  { text: 'Twitter', href: 'https://twitter.com/AyoupOuaantar' },
  { text: 'Github', href: 'https://github.com/Ayoup1' },
  { text: 'Instagram', href: 'https://www.instagram.com/ayoupou' },
  { text: 'LinkedIn', href: 'https://www.linkedin.com/in/ayoubouaantar' },
];